module.exports={
  "name": "js",
  "match": "^(js|javascript)$",
  "kw0": ["break","case","continue","import","export","default","do","else",
    "for","goto","if","return","switch","typeof","while","undefined","null",
    "eval","self","window","try","catch","throw","new"],
  "kw1":["Array","Boolean","Date","Error","EvalError","Function","Infinity",
    "JSON","Math","NaN","Number","Object","RangeError","ReferenceError",
    "RegExp","String","SyntaxError","TypeError","URIError","ArrayBuffer",
    "Buffer","DataView","Float32Array","Float64Array","Int16Array",
    "Int32Array","Int8Array","Intl","Map","Promise","Proxy","Reflect","Set",
    "Symbol","Uint16Array","Uint32Array","Uint8Array","Uint8ClampedArray",
    "WeakMap","WeakSet"],
  "kw2":["function","var"],
  "rules": [
    ["area comment","^/\\*([^*]|\\*(?!/))*\\*/$"],
    ["area comment continue","^/\\*([^*]|\\*(?!/))*\\*?$"],
    ["line comment","^//[^\\n]*$"],
    ["template string","^(`([^`$]|\\\\`|\\$\\{([^}]|`([^`]|\\\\`)*`)*\\}?|\\$([^{]|$))*`?)$",[
      [
        ["text","^([^$]|\\\\\\$|\\$[^{])*$"],
        ["template expr","^\\$\\{([^`}]|\\\\\\}|`([^`$]|\\\\`|\\$[^{]|\\$\\{[^}]*\\}?)*(`|$))*\\}?$"]
      ]
    ]],
    ["regex","^/([^/]|\\\\/)*/[a-z]*$"],
    ["double quote","^\"([^\"\\n]|\\\\\")*\"?$"],
    ["single quote","^'([^'\\n]|\\\\')*'?$"],
    ["directive","^#(\\S*)$"],
    ["open paren","^\\($"],
    ["close paren","^\\)$"],
    ["open square","^\\[$"],
    ["close square","^\\]$"],
    ["open curly","^{$"],
    ["close curly","^}$"],
    ["operator","^([-<>~%^&*/+?|.,:;]|<<|>>|\\*\\*|\\|\\||&&|--|\\+\\+|[-+*|&%/]=|[!=](=?=?|>))$"],
    ["identifier","^([_A-Za-z]\\w*)$"],
    ["number","^(\\d*\\.?\\d+([eE][-+]?\\d*)?|0x\\d+)$"],
    ["whitespace","^(\\s+)$"],
    ["line continue","^\\\\\\n?$"],
    ["shebang","^(#![^\\n]*)$"]
  ]
}
