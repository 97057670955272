module.exports={
  "name": "sh",
  "match": "^(ba)?sh$",
  "kw0":["alias","bg","bind","break","builtin","caller","cd","command",
    "compgen","complete","compopt","continue","declare","typeset","dirs",
    "disown","echo","enable","eval","exec","exit","export","fc","fg","getopts",
    "hash","help","history","jobs","kill","let","local","logout","mapfile",
    "readarray","popd","printf","pushd","pwd","read","readonly","return","set",
    "shift","shopt","source","suspend","test","[","]","times","trap","type",
    "ulimit","umask","unalias","unset","wait","."],
  "kw1":["ls","sed","grep"],
  "rules": [
    ["shebang","^(#![^\\n]*)$"],
    ["line comment","^#[^\\n]*$"],
    ["whitespace","^(\\s+)$"],
    ["set var","^(export\\s+)?(\\w+=)(.*)$",[
      [
        ["identifier","^\\w+$"],
        ["whitespace","^\\s+$"]
      ],
      [["variable","^.+$"]],
      [
        ["argument","^([^'\"\\s<>|\\\\$]+)$"],
        ["variable","^(\\$[\\w*@#?$!-]*)$"],
        ["single quote","^'[^']*'?$"],
        ["double quote","^\"([^\"]|\\\\\")*\"?$"]
      ]
    ]],
    ["open paren","^\\($"],
    ["close paren","^\\)$"],
    ["command","^([^\\s'\"|&;()]+)((?:[^\\n'\"|&;]|\\\\[\\n'\"|&;]|'[^']*(?:'|$)|\"(?:[^\"]|\\\\\")*(?:\"|$)|<<(\\S*)(?:.|\\n(?!\\3))*(?:\\n\\1?)?)*)$", [
      [
        ["identifier","^.+$"]
      ],
      [
        ["argument","^([^'\"\\s<>|$]+|\\\\\\$)*$"],
        ["variable","^(\\$[\\w*@#?$!-]*)$"],
        ["command-substitution","^\\$\\{[^}]*\\}?$"],
        ["single quote","^'[^']*'?$"],
        ["double quote","^(\"(?:[^\"]|\\\\\")*\"?)$",[[
          ["variable","^(\\$[\\w*@#?$!-]*)$"],
          ["command-substitution","^\\$\\{[^}]*\\}?$"],
          ["text","^([^$]|\\\\\\$?)+$"]
        ]]],
        ["heredoc","^(<<)(\\S*)((?:.|\\n(?!\\2))*)(\\n\\2)?$",[
          [["operator","^.+$"]],
          [["operator","^.+$"]],
          [
            ["variable","^(\\$[\\w*@#?$!-]*)$"],
            ["command-substitution","^\\$\\{[^}]*\\}?$"],
            ["text","^([^$]|\\\\\\$?)+$"]
          ],
          [
            ["whitespace","^\\s+$"],
            ["operator","^\\S+$"]
          ]
        ]],
        ["operator","^([<|&;!\\[\\]]|>>?|\\|\\||&&)$"],
        ["whitespace","^(\\s+)$"],
        ["line continue","^\\\\\\n?$"]
      ]
    ] ],
    ["operator","^([|&;]|\\|\\||&&)$"]
  ]
}
